/* eslint-disable no-undef */
$(`.cat-filter .active-item`).click(function () {
  $(this).next(`.cat-list`).slideToggle();
});
$(`.cat-filter .cat-item`).click(function () {
  $(`.cat-filter .cat-item`).removeClass(`active`);
  $(`.cat-filter .active-item p`).text($(this).find(`label`).text());
  $(this).addClass(`active`);
});
$(`.cat-filter .cat-item input`).on(`change`, function () {
  $(`.cat-filter  .cat-list`).slideToggle();
});

$(`.heading-filter .drop p, .last-filter .drop p`).click(function () {
  $(this).next(`.drop-list`).slideToggle();
});

$(`.drop label`).click(function () {
  $(`.drop label`).removeClass(`active`);
  $(`.heading-filter .drop p`).text($(this).text());
  $(this).addClass(`active`);
});

$(`.drop label input`).on(`change`, function () {
  $(this).parents(".drop-list").slideToggle();
});

$(`.home-slider`).slick({
  prevArrow: $(`.home .slider-nav .prev`),
  nextArrow: $(`.home .slider-nav .next`),
  dots: true,
  infinite: true,
  adaptiveHeight: true,
  fade: true,
});

$(`.similar-slider`).slick({
  prevArrow: $(`.similar-products .pagination .prev`),
  nextArrow: $(`.similar-products .pagination .next`),
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        variableWidth: true,
      },
    },
  ],
});
$(`.product-slider .slider-content`).slick({
  infinite: true,
  adaptiveHeight: true,
  fade: true,
  arrows: false,
  asNavFor: `.product-slider .slider-nav`,
  slidesToShow: 1,
  slidesToScroll: 1,
});

$(`.product-slider .slider-nav`).slick({
  infinite: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: `.product-slider .slider-content`,
  focusOnSelect: true,
  vertical: true,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        vertical: false,
      },
    },
    {
      breakpoint: 581,
      settings: {
        vertical: false,
        slidesToShow: 4,
      },
    },
  ],
});

$(`header .open-filter`).click(function () {
  $(`.header .container .row-b .right`).slideToggle();
});

$(`.open-menu`).click(function () {
  $(`.header .container .row-b .left .menu`).slideToggle().css({
    display: `flex`,
  });
});

$(`.tabs-nav li`).click(function () {
  let idx = $(this).index();
  $(`.tabs-nav li`).removeClass(`active`);
  $(`.tabs-item`).removeClass(`active`);
  $(this).addClass(`active`);
  $(`.tabs-item`).eq(idx).addClass(`active`);
});

$(".tabs-vertical-heading").click(function () {
  $(this).next(".tabs-vertical-content").slideToggle();
});

$(".toggle").click(function () {
  $("form.fiz, form.yr").removeClass("active");
  if ($(this).find("input").is(":checked")) {
    $("form.fiz").addClass("active");
  } else {
    $("form.yr").addClass("active");
  }
});

$("a.login").click(function (e) {
  e.preventDefault();
  $(".modal-login").fadeIn();
  $(".bg-modal").fadeIn();
});
$(".bg-modal").click(function () {
  $(".modal-login").fadeOut();
  $(".bg-modal").fadeOut();
});
